import React, {Component} from "react";
import Layout from "../layouts";
import {Container, Row, Col} from "reactstrap";
import CustomModal from "../components/modal";
import Select from 'react-select';
import felixmottlstrasse16_1 from "../images/projekte-felix-mottl-strasse-16-1.jpg";
import felixmottlstrasse16_2 from "../images/projekte-felix-mottl-strasse-16-2.jpg";
import felixmottlstrasse16_3 from "../images/projekte-felix-mottl-strasse-16-3.jpg";
import felixmottlstrasse16_4 from "../images/projekte-felix-mottl-strasse-16-4.jpg";
import felixmottlstrasse16_5 from "../images/projekte-felix-mottl-strasse-16-5.jpg";
import felixmottlstrasse16_6 from "../images/projekte-felix-mottl-strasse-16-6.jpg";
import ohiostrasse8_1 from "../images/projekte-ohiostrasse-8-1.jpg";
import ohiostrasse8_2 from "../images/projekte-ohiostrasse-8-2.jpg";
import ohiostrasse8_3 from "../images/projekte-ohiostrasse-8-3.jpg";
import ohiostrasse8_4 from "../images/projekte-ohiostrasse-8-4.jpg";
import ohiostrasse8_5 from "../images/projekte-ohiostrasse-8-5.jpg";
import ohiostrasse8_6 from "../images/projekte-ohiostrasse-8-6.jpg";
import ohiostrasse10_1 from "../images/projekte-ohiostrasse-10-1.jpg";
import ohiostrasse10_2 from "../images/projekte-ohiostrasse-10-2.jpg";
import ohiostrasse10_3 from "../images/projekte-ohiostrasse-10-3.jpg";
import ohiostrasse10_4 from "../images/projekte-ohiostrasse-10-4.jpg";
import ohiostrasse10_5 from "../images/projekte-ohiostrasse-10-5.jpg";

class Projekte extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLeftLineHidden: false,
            isRightLineHidden: false,
            buildingTypeOptions: [
                { value: 'Wohnbau', label: 'Wohnbau' },
                { value: 'Gewerbe', label: 'Gewerbe' }
            ],
            buildingTypeOptionsSelected: [],
            statusOptions: [
                { value: 'In Umsetzung', label: 'In Umsetzung' },
                { value: 'Fertiggestellt', label: 'Fertiggestellt' }
            ],
            statusOptionsSelected: [],
            sortOptions: [
                { value: 'A-Z', label: 'A-Z' },
                { value: 'Z-A', label: 'Z-A' }
            ],
            sortOptionSelected: '',
            sortDefault: true,
            headerContents: [
                {
                    index: 0,
                    image: felixmottlstrasse16_1,
                    headline: 'Stadtvillen im Musikerviertel',
                    status: 'Fertiggestellt',
                    category: 'Wohnbau',
                    year: '2019',
                    place: 'Karlsruhe',
                    area: 'Von 150 bis 360 m²',
                    isOpen: false,
                    altImages: [
                        felixmottlstrasse16_2,
                        felixmottlstrasse16_3,
                        felixmottlstrasse16_4,
                        felixmottlstrasse16_5,
                        felixmottlstrasse16_6
                    ],
                    concept: 'Die Herausforderung der Bebauung der Stadtvillen im Musikerviertel in Karlsruhe bestand darin, den doch sehr eingeschränkten Bebauungsplan so umzusetzen, dass sich die neu entstandene moderne Architektur in den vorhandenen Altbestand des traditionellen Musikerviertels einpasst, ohne als Fremdkörper zu wirken. Die beiden Stadtvillen folgen der Begrenzung der Kubatur in Tiefe und Länge und der vorgegebenen Geschosse und mit einem gekappten Walmdach aus verzinktem Blech ab. Raumhohe, schlitzartige Fensteröffnungen zur belebten Straße bilden den Kontrast zur großzügigen Verglasungen im rückwertigen parkartigen Gartenbereich. Schlicht und den Proportionen einer klassischen Stadtvilla folgend, sind in den beiden Gebäuden insgesamt fünf Eigentumswohnungen im gehobenen Standard realisiert. Die Konzeption und komplette Planung, die Vermarktung sowie die Bauleitung und Kundenbetreuung wurde gesamthaft von Antje Abel Architekten realisiert. ',
                    implementation: 'Die Materialien in den Wohnungen sind individuell auf die Wünsche der Käufer abgestimmt. Zielführend war der Anspruch der stimmigen Gestaltung jeder einzelnen Wohnung als Gesamtwerk in Design und Optik. Die Böden wurden je nach Wohndesign als helle Eichenböden mit langen Dielen verlegt oder mit großen Fliesenformaten 1,20 m x 1,20 im Innen- und Außenbereich. Im Fall einer komplett weiß gestalteten Wohnung wurden die 12 Meter langen Dielen mit Lasur eingelassen, Küchen- und Badbereiche wurden an Wand und Boden mit einheitlichem Beton Ciré fugenlos gespachtelt. Eine einläufige Stahltreppe aus massivem Schwarzstahl mit geölter Oberfläche verbindet untereinander die Geschosse einer Maisonette-Wohnung im kleineren Haus. Außergewöhnlich großzügig und oft unbeachtet in der Ausstattung verbindet eine 400 qm große Tiefgarage aus Sichtbeton und Fliesenbelag  im Untergeschoss die beiden Häuser. Über die dort eingerichteten separaten Garagen mit eigenen Toren pro Einheit werden jeweils die Wohnungen direkt erschlossen.',
                    otherContents: [
                        1,
                        2
                    ]
                },
                {
                    index: 1,
                    image: ohiostrasse8_1,
                    headline: 'Bürogebäude O8',
                    status: 'Fertiggestellt',
                    category: 'Gewerbe',
                    year: '2006',
                    place: 'Karlsruhe',
                    area: '3.000 m²',
                    isOpen: false,
                    altImages: [
                        ohiostrasse8_2,
                        ohiostrasse8_3,
                        ohiostrasse8_4,
                        ohiostrasse8_5,
                        ohiostrasse8_6
                    ],
                    concept: 'Radikal pur in der Gestaltung wurde das Headquarter für eine renommierte Firma in Karlsruhe konzipiert. Die Geschossfläche von 3.000 qm ist in drei Etagen á 1.000 qm Bürofläche aufgeteilt und kann bei Bedarf in sechs Einheiten aufgeteilt werden. Die mittig zentral angeordnete repräsentative Eingangshalle ist mit zwei symmetrisch angeordneten Glasaufzügen ausgestattet. Vorgesehen ist eine Zonierung der Büroflächen in Einzelarbeitsplätze mit Multifunktionszonen  für Meetings, Archivierung und Projektarbeit im Team. Entlang der durchgehenden Fensterfronten sind Einzelarbeitsplätze angeordnet,  zu den Raummitten hin befinden sich die Multifunktionszonen. Die Abgrenzung zu den gemeinschaftlich genutzten Flächen erfolgt durch raum hohe Glaselemente, die sich mit weiß gehaltenen Wandflächen abwechseln. An den Stirnseiten des 55 Meter langen und 17.50 Meter breiten Gebäudes sind die Treppenhäuser angedockt, die unabhängig von der Haupthalle eine separate Erschließung ermöglichen.',
                    implementation: 'Beton und Glas sind die dominierenden Materialien des monolithischen Baukörpers. Als Naturmaterial weist Beton sehr gute Qualitäten auf, um ihn unterstützend zur installierten luftgeführten Bauteilaktivierung hinzuzuziehen. Die jeweils kompakten und nahezu geschlossenen Stirnseiten bilden mit dem auskragenden Flachdach des Mittbaukörpers einen „Rahmen“ in den eine dreigeschossige Pfosten-Riegel-Fassade eingestellt ist. Die Betonwände an den Außenfassaden sind zweischalig mit innenliegender Dämmung aufgebaut. Als technische Innovation wurde eine Bauteilaktivierung installiert, über die das Kühlen, Heizen und die Belüftung mit Frischluft erfolgt. Die Temperaturen im Gebäude wirken somit immer ausgeglichen und natürlich, was Einfluss auf die Produktivität und das Wohlbefinden der Mitarbeiter hat. Die verwendeten Materialien sind reduziert auf sichtbare Betonflächen, weiße Trockenbauwände und geräucherte Eichendielen in Halle und Treppenhäusern.',
                    otherContents: [
                        2,
                        0
                    ]
                },
                {
                    index: 2,
                    image: ohiostrasse10_1,
                    headline: 'Bürogebäude O10',
                    status: 'Fertiggestellt',
                    category: 'Gewerbe',
                    year: '2009',
                    place: 'Karlsruhe',
                    area: '2.600 m²',
                    isOpen: false,
                    altImages: [
                        ohiostrasse10_2,
                        ohiostrasse10_3,
                        ohiostrasse10_4,
                        ohiostrasse10_5
                    ],
                    concept: 'Als Verwaltungsgebäude für eine Steuerberatungsgesellschaft und eine Rechtsanwaltskanzlei wurde der Bürokomplex in direkter Nachbarschaft zum Bürogebäude O8 errichtet. Somit konnte ein architektonisches Gesamtkonzept auch für die Gestaltung der Außenanlage umgesetzt werden. Das Raumprogramm für 2.600 m2 wurde im Vorfeld mit den späteren Nutzern auf deren Bedürfnisse abgestimmt. Von der Planung bis zur Möblierung und Gestaltung der Räumlichkeiten wurden alle Leistungsphasen von den Antje Abel Architekten realisiert. Auf drei Geschossen wurden neben der Kundenrezeption und den unterschiedlich großen Besprechungsräumen im Erdgeschoss auf den weiteren Etagen Einzel- und Zweierbüroarbeitsplätze geschaffen. Das vierte Obergeschoß ist als großzügiges Großraumbüro mit Dachterrassen an einen weiteren Nutzer vermietet.',
                    implementation: 'In Anlehnung an das Nachbargebäude und als bevorzugtes Material von Antje Abel Architekten sind Beton mit integrierter Betonkernaktivierung und geschoßhohe Fensterfronten die wesentlichen Gestaltungselemente am Projekt. Zwei unterschiedlich große Gebäuderiegel in Höhe und in Länge aus hellem Sichtbeton durchqueren sich jeweils in ihrer Mitte und bilden im Grundriss ein Kreuz. Die komplett geschlossenen Schmalseiten des Querriegels mit an- und aufeinandergesetzten Betonfertigteilen von 3 Metern x 4 Metern stehen hervor und bilden zusammen mit dem auskragenden Dach einen Rahmen um den Längsriegel. Die Trennwände mit integrierten Regalsystemen und Schränken wurden aus hochwertigen lichtgrauen Metalloberflächen hergestellt. Raum hohe Glaspaneele und Glastüren durchfluten den mittigen breiten Erschließungsflur. Die Büroetage im Dachgeschoß bietet gewollt einen sehr offenen Grundriss mit großen Fensterfronten zu den beiden Terrassen und einem herrlichen Blick in die umliegenden Baumwipfel.',
                    otherContents: [
                        1,
                        0
                    ]
                }
            ],
            headerContentsVisible: []
        };
    }

    componentDidMount() {
        let headerContentsTemp = this.state.headerContents;
        headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
        this.setState((state) => ({
            headerContentsVisible: headerContentsTemp,
        }));
    }

    toggleModal = (index, event = null) => {
        let headerContentsTemp = this.state.headerContents;
        headerContentsTemp.find(obj => {
            return obj.index === index
        }).isOpen = !headerContentsTemp.find(obj => {
            return obj.index === index
        }).isOpen;
        this.setState((state) => ({
            headerContents: headerContentsTemp,
        }));

        document.body.style.paddingRight = '0';
        if (event !== null && !isNaN(event)) {
            this.toggleModal(event);
            setTimeout(() => {
                document.body.style.paddingRight = '16px';
            }, 500);
        }
    }

    render () {
        if (typeof document !== `undefined`) {
            document.documentElement.classList.remove('nav-open');
        }

        return (
            <Layout location={this.props.location} title="Antje Abel Architekten | Architekturbüro aus Karlsruhe" description="Freie Architekten mit Sitz in Karlsruhe | Neubau, Umbau & Sanierung | Einfamilienhäuser, Architektenhäuser, Mehrfamilienhäuser, Gewerbeimmobilien." keywords="Neubau, Umbau & Sanierung, Einfamilienhäuser, Architektenhäuser, Mehrfamilienhäuser, Gewerbeimmobilien, Projekte, " robots="index, follow">
                <div className="section section-projects">
                    <h1>
                        Wir planen und entwickeln Gebäude, Räume, Strukturen, die begeistern.
                    </h1>
                    <Container>
                        <Row>
                            <Col lg="12">
                                <div className="selector-multi">
                                    <Row>
                                        <Col lg="4">
                                            <span>
                                                Gebäudetyp
                                            </span>
                                            <Select
                                                placeholder="Alle Gebäudetypen"
                                                onChange={(event) => {
                                                    let list = [];
                                                    if (event !== null) {
                                                        list = event.map((value) => {
                                                            return value.value;
                                                        })
                                                    }
                                                    this.setState((state) => ({
                                                        buildingTypeOptionsSelected: list,
                                                    }));
                                                    let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                        if ((list.length === 0 || list.includes(value.category)) && (this.state.statusOptionsSelected.length === 0 || this.state.statusOptionsSelected.includes(value.status))) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }).map((value) =>  {return value});
                                                    if (this.state.sortOptionSelected === 'A-Z') {
                                                        headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                                    } else {
                                                        headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                                    }
                                                    this.setState((state) => ({
                                                        headerContentsVisible: headerContentsTemp,
                                                    }));
                                                }}
                                                isMulti
                                                name="buildingTypes"
                                                options={this.state.buildingTypeOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onMenuOpen={() => {
                                                    this.setState((state) => ({
                                                        isLeftLineHidden: true
                                                    }));
                                                }}
                                                onMenuClose={() => {
                                                    this.setState((state) => ({
                                                        isLeftLineHidden: false
                                                    }));
                                                }}
                                            />
                                            <div className="vertical-line" style={this.state.isLeftLineHidden ? {display:'none'} : {}} />
                                        </Col>
                                        <Col lg="4">
                                            <span>
                                                Projektstatus
                                            </span>
                                            <Select
                                                placeholder="Projektstatus"
                                                onChange={(event) => {
                                                    let list = [];
                                                    if (event !== null) {
                                                        list = event.map((value) => {
                                                            return value.value;
                                                        })
                                                    }
                                                    this.setState((state) => ({
                                                        statusOptionsSelected: list,
                                                    }));
                                                    let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                        if ((list.length === 0 || list.includes(value.status)) && (this.state.buildingTypeOptionsSelected.length === 0 || this.state.buildingTypeOptionsSelected.includes(value.category))) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }).map((value) =>  {return value});
                                                    if (this.state.sortOptionSelected === 'A-Z') {
                                                        headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                                    } else {
                                                        headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                                    }
                                                    this.setState((state) => ({
                                                        headerContentsVisible: headerContentsTemp,
                                                    }));
                                                }}
                                                isMulti
                                                name="statuses"
                                                options={this.state.statusOptions}
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onMenuOpen={() => {
                                                    this.setState((state) => ({
                                                        isRightLineHidden: true,
                                                        isLeftLineHidden: true
                                                    }));
                                                }}
                                                onMenuClose={() => {
                                                    this.setState((state) => ({
                                                        isRightLineHidden: false,
                                                        isLeftLineHidden: false
                                                    }));
                                                }}
                                            />
                                        </Col>
                                        <Col lg="4">
                                            <div className="vertical-line" style={this.state.isRightLineHidden ? {display:'none'} : {}} />
                                            <span>
                                                Sortierung
                                            </span>
                                            <Select
                                                placeholder="Sortierung"
                                                onChange={(event) => {
                                                    this.setState((state) => ({
                                                        sortOptionSelected: event.value,
                                                    }));
                                                    let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                        if ((this.state.statusOptionsSelected.length === 0 || this.state.statusOptionsSelected.includes(value.status)) && (this.state.buildingTypeOptionsSelected.length === 0 || this.state.buildingTypeOptionsSelected.includes(value.category))) {
                                                            return true;
                                                        }
                                                        return false;
                                                    }).map((value) =>  {return value});
                                                    if (event.value === 'A-Z') {
                                                        headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                                    } else {
                                                        headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                                    }
                                                    this.setState((state) => ({
                                                        headerContentsVisible: headerContentsTemp,
                                                    }));
                                                }}
                                                className="basic-single"
                                                classNamePrefix="select"
                                                defaultValue={this.state.sortOptions[0]}
                                                isClearable={false}
                                                name="sort"
                                                options={this.state.sortOptions}
                                                onMenuOpen={() => {
                                                    this.setState((state) => ({
                                                        isRightLineHidden: true
                                                    }));
                                                }}
                                                onMenuClose={() => {
                                                    this.setState((state) => ({
                                                        isRightLineHidden: false
                                                    }));
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <div className="selector-single">
                                    <span>
                                                Gebäudetyp
                                            </span>
                                    <Select
                                        placeholder="Alle Gebäudetypen"
                                        onChange={(event) => {
                                            let list = [];
                                            if (event !== null) {
                                                list = event.map((value) => {
                                                    return value.value;
                                                })
                                            }
                                            this.setState((state) => ({
                                                buildingTypeOptionsSelected: list,
                                            }));
                                            let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                if ((list.length === 0 || list.includes(value.category)) && (this.state.statusOptionsSelected.length === 0 || this.state.statusOptionsSelected.includes(value.status))) {
                                                    return true;
                                                }
                                                return false;
                                            }).map((value) =>  {return value});
                                            if (this.state.sortOptionSelected === 'A-Z') {
                                                headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                            } else {
                                                headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                            }
                                            this.setState((state) => ({
                                                headerContentsVisible: headerContentsTemp,
                                            }));
                                        }}
                                        isMulti
                                        name="buildingTypes"
                                        options={this.state.buildingTypeOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="selector-single">
                                    <span>
                                                Projektstatus
                                            </span>
                                    <Select
                                        placeholder="Projektstatus"
                                        onChange={(event) => {
                                            let list = [];
                                            if (event !== null) {
                                                list = event.map((value) => {
                                                    return value.value;
                                                })
                                            }
                                            this.setState((state) => ({
                                                statusOptionsSelected: list,
                                            }));
                                            let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                if ((list.length === 0 || list.includes(value.status)) && (this.state.buildingTypeOptionsSelected.length === 0 || this.state.buildingTypeOptionsSelected.includes(value.category))) {
                                                    return true;
                                                }
                                                return false;
                                            }).map((value) =>  {return value});
                                            if (this.state.sortOptionSelected === 'A-Z') {
                                                headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                            } else {
                                                headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                            }
                                            this.setState((state) => ({
                                                headerContentsVisible: headerContentsTemp,
                                            }));
                                        }}
                                        isMulti
                                        name="statuses"
                                        options={this.state.statusOptions}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                    />
                                </div>
                                <div className="selector-single last">
                                    <span>
                                                Sortierung
                                            </span>
                                    <Select
                                        placeholder="Sortierung"
                                        onChange={(event) => {
                                            this.setState((state) => ({
                                                sortOptionSelected: event.value,
                                            }));
                                            let headerContentsTemp = this.state.headerContents.filter((value) => {
                                                if ((this.state.statusOptionsSelected.length === 0 || this.state.statusOptionsSelected.includes(value.status)) && (this.state.buildingTypeOptionsSelected.length === 0 || this.state.buildingTypeOptionsSelected.includes(value.category))) {
                                                    return true;
                                                }
                                                return false;
                                            }).map((value) =>  {return value});
                                            if (event.value === 'A-Z') {
                                                headerContentsTemp.sort((a, b) => (a.headline > b.headline) ? 1 : -1);
                                            } else {
                                                headerContentsTemp.sort((a, b) => (a.headline < b.headline) ? 1 : -1);
                                            }
                                            this.setState((state) => ({
                                                headerContentsVisible: headerContentsTemp,
                                            }));
                                        }}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        defaultValue={this.state.sortOptions[0]}
                                        isClearable={false}
                                        name="sort"
                                        options={this.state.sortOptions}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <Container>
                        <Row>
                            {this.state.headerContentsVisible.map((value, index) => {
                                return (
                                    <Col key={value.index} xs="12" sm="12" md="6" lg="6" xl="6">
                                        <div className="project-wrapper" onClick={() => {this.toggleModal(value.index)}} style={{backgroundImage: `url(${value.image})`}}>
                                            <div className="overlay-wrapper" />
                                            <div className="text-wrapper">
                                                <p>{value.category}, {value.year}</p>
                                                <h3>{value.headline}</h3>
                                            </div>
                                        </div>
                                    </Col>
                                )
                            })}
                            {this.state.headerContents.map((value, index) => {
                                return (
                                    <CustomModal key={index} content={value} others={this.state.headerContents} isOpen={this.state.headerContents.find(obj => {
                                        return obj.index === value.index
                                    }).isOpen} onClick={(event) => {this.toggleModal(value.index, event);}}/>
                                )
                            })}
                        </Row>
                    </Container>
                </div>
            </Layout>
        );
    }
}

export default Projekte;

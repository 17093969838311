import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "gatsby";

function Footer(props) {
  return (
      <>
        <div className="section section-short-end" id="contact">
          <Container>
            <Row>
              <Col className="mailto-wrapper-up" sm="12" xs="12">
                <a className="mailto" href="mailto:info@antje-abel-architekten.de"><div className="arrow-right"></div></a>
              </Col>
              <Col lg="6" md="10">
                <h3 className="subtitle contact">
                  Projektanfrage, Bewerbung oder Feedback?
                </h3>
                <h2 className="title">
                  Jetzt Kontakt aufnehmen
                </h2>
              </Col>
              <Col lg="6" md="2" className="mailto-wrapper-right">
                <a className="mailto" href="mailto:info@antje-abel-architekten.de"><div className="arrow-right"></div></a>
              </Col>
            </Row>
          </Container>
        </div>
        <footer className="section footer">
          <Container>
            <Row>
              <Col lg="3" md="6" sm="6" xs="12">
                <nav className="footer-nav">
                  <ul>
                    <li className="title">
                      Antje Abel Architekten
                    </li>
                    <li className="text">
                      Copyright 2020 AMA Architekten.
                      <br />
                      Alle Rechte vorbehalten.
                      <br />
                      Made by <a href="https://newww.agency" target="_blank">newww.agency</a>
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col lg="3" md="6" sm="6" xs="12">
                <nav className="footer-nav">
                  <ul>
                    <li className="title">
                      Über
                    </li>
                    <li>
                      <Link
                          className="link"
                          to='/projekte'
                      >
                        Projekte
                      </Link>
                    </li>
                    <li>
                      <Link
                          className="link"
                          to='/buero'
                      >
                        Büro
                      </Link>
                    </li>
                    <li>
                      <Link
                          className="link"
                          to='/kontakt'
                      >
                        Kontakt
                      </Link>
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col lg="3" md="6" sm="6" xs="12">
                <nav className="footer-nav">
                  <ul>
                    <li className="title">
                      Rechtliches
                    </li>
                    <li>
                      <Link
                          className="link"
                          to='/datenschutz'
                      >
                        Datenschutz
                      </Link>
                    </li>
                    <li>
                      <Link
                          className="link"
                          to='/impressum'
                      >
                        Impressum
                      </Link>
                    </li>
                  </ul>
                </nav>
              </Col>
              <Col lg="3" md="6" sm="6" xs="12">
                <nav className="footer-nav">
                  <ul>
                    <li className="title">
                      Follow us
                    </li>
                    <li>
                      <a className="link" href="https://facebook.com" rel="noreferrer" target="_blank">Facebook</a>
                    </li>
                    <li>
                      <a className="link" href="https://houzz.com" rel="noreferrer" target="_blank">Houzz</a>
                    </li>
                    <li>
                      <a className="link" href="https://instagram.com" rel="noreferrer" target="_blank">Instagram</a>
                    </li>
                  </ul>
                </nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
  );
}

export default Footer;
